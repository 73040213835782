<template>
  <div>
    <v-toolbar flat dark>
      <v-toolbar-title>Confirmed Paper</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items
        class="hidden-sm-and-down"
        v-for="(option, index) in getFillUpOptions"
        :key="index"
      >
        <!-- :href="`${getAssetUrl}/${formFillUpData.application_form_url}`" -->
        <v-btn
          v-if="formFillUpData.student_confirm_status == 1 && (option.ff_apply_activity || option.ff_college_confirm_activity)"
          target="_blank"
          @click="getformFillupDetelsbyID(option.id)"
        >
          Application Form
          <v-icon right dark>cloud_download</v-icon>
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>
    <v-data-table
      :headers="headers"
      :items="formFillUpData.papers"
      class="elevation-1"
      hide-actions
    >
      <template v-slot:items="props">
        <td>{{ props.item.core_paper.paper_name }}</td>
        <td class="text-xs-left">{{ props.item.paper_code }}</td>
        <td class="text-xs-right">
          {{ props.item.core_paper.paper_types.paper_type }}
        </td>
      </template>
    </v-data-table>
  </div>
</template>
<script>
export default {
  props: ["formFillUpData"],
  computed: {
    getAssetUrl() {
      return this.$store.getters.getAssetUrl;
    },
    authStudent() {
      return this.$store.getters.getStudentProfile;
    },
    getFillUpOptions() {
      return this.$store.getters.getStudentFormFillUpOptions;
    },
    getTadaByIDOfFormFillup() {
      return this.$store.getters.getTadaByIDOfFormFillup;
    },
  },
  data() {
    return {
      headers: [
        {
          text: "Paper Name",
          align: "left",
          sortable: false,
          value: "core_paper.paper_name",
        },
        { text: "Paper Code", value: "paper_code" },
        {
          text: "Paper Type",
          align: "right",
          value: "core_paper.paper_types.paper_type",
        },
      ],
    };
  },
  created() {
    this.$store.dispatch("getStudentProfile").then((res) => {
      this.$store.dispatch("fetchStudentFormFillUpOptions", this.authStudent);
    });
  },
  watch: {
    getTadaByIDOfFormFillup() {
      window.open(this.getTadaByIDOfFormFillup);
    },
  },
  methods: {
    getformFillupDetelsbyID(optioners) {
      // console.log("jdbsdjkc", optioners);
      this.$store
        .dispatch("fetchStudentFormFillupDetailsByID", optioners)
        .then(() => {
          console.log("Tanvir");
        });
    },
  },
};
</script>
